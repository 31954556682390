/* Target the dropdown menu options */
.custom-select-dropdown .ant-select-item {
  font-size: 10px !important;
}

/* Target the selected items in the Select component */
.custom-select .ant-select-selection-item {
  font-size: 10px !important;
}

/* Target the input text inside the Select component */
.custom-select .ant-select-selection-search-input {
  font-size: 10px !important;
}

/* Target the placeholder text */
.custom-select .ant-select-selection-placeholder {
  font-size: 10px !important;
}

/* Target the input fields inside the RangePicker */
.custom-range-picker .ant-picker-input input {
  font-size: 10px !important;
}

/* Target the suffix icon (e.g., calendar icon) */
.custom-range-picker .ant-picker-suffix {
  font-size: 10px !important;
}

/* Target the dropdown calendar */
.custom-range-picker .ant-picker-panel {
  font-size: 10px !important;
}

/* Target the dropdown calendar headers (e.g., month and year) */
.custom-range-picker .ant-picker-header {
  font-size: 10px !important;
}

/* Target the dropdown calendar cells (e.g., days) */
.custom-range-picker .ant-picker-cell {
  font-size: 10px !important;
}

/* Target the dropdown calendar footer (e.g., "Today" button) */
.custom-range-picker .ant-picker-footer {
  font-size: 10px !important;
}