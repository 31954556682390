/* Dropdown menu options */
.custom-select-dropdown .ant-select-item {
  font-size: 30px !important;
}

.ant-select-selection-item {
  font-size: 10px !important;
}

.custom-range-picker .ant-picker-input input {
  font-size: 10px;
}

.custom-range-picker .ant-picker-suffix {
  font-size: 10px;
}
