.text-center {
  text-align: center;
}

.action {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.action a {
  font-size: 11px;
}
