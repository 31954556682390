.section {
  background-color: #ffff;
  padding: 0px 0px 1px 0px;
  margin-bottom: 24px;
  border-radius: 8px;
  border: 1px solid #d9d9d9; 
}

.section-header {
  text-align: left; 
  text-indent: 1.4%; 
  background-color: #f0f5ff;
  padding: 8px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  border-radius: 8px 8px 0px 0px;
}

.section-content{
  padding: 16px;
}

:where(.css-dev-only-do-not-override-3rel02).ant-form-vertical .ant-form-item:not(.ant-form-item-horizontal) .ant-form-item-label,
:where(.css-dev-only-do-not-override-3rel02).ant-form-vertical .ant-form-item:not(.ant-form-item-horizontal) .ant-col-24.ant-form-item-label,
:where(.css-dev-only-do-not-override-3rel02).ant-form-vertical .ant-form-item:not(.ant-form-item-horizontal) .ant-col-xl-24.ant-form-item-label {
padding-left: 3%;
}


.ant-form-item {
  margin-bottom: 12px;
}

.ant-row .ant-col {
  padding-left: 8px;
  padding-right: 8px;
}

.custom-form-item .ant-form-item-label > label {
  margin-left: 0.1rem !important;
  margin-bottom: 0.1rem !important;
}

.ant-select-item-option {
  display: flex;
  justify-content: flex-start; 
}

.ant-select-item-option-content {
  text-align: left; 
}

