.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

 @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} 

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} 

.App-link {
  color: #61dafb;
}

.header {
  background: #fff;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
} 

/* .logo {
  position: absolute;
  left: 20px;
} */

.header-title {
  font-size: 20px;
  color: white;
  float: right;
}

.table-operations {
  margin-bottom: 16px;
}

/* .site-layout-background {
  background: #fff;
  padding: 24px;
  min-height: 360px;
} */

h2, h3 {
  margin-bottom: 16px;
} 

.ant-form-item {
  margin-bottom: 12px;
}

.ant-menu-item-selected {
  background-color: #1677FF !important; 
}

.ant-menu-item-selected a {
  color: white !important;
} 

/* Increasing specificity */
.ant-menu-item.ant-menu-item-selected .anticon {
  /* color: black !important; */
  color: white !important;
}

.menu-item {
  color: #FFFFFF !important;
  
}

.ant-menu-item .anticon {
  color: #FFFFFF !important;
}

/*  */.menu-item:hover {
  color: rgb(168, 166, 166) !important;
}

.ant-menu-item:hover .anticon {
  color: rgb(168, 166, 166) !important;
}

/* styles.css */
.date-picker {
  color: gray;
}

.ant-picker-input > input {
  color: gray !important;  /* Ensures input text color is black */
}

.custom-select-option {
  color: gray !important; /* Ensures the text color is black */
}


.arrow {
  position: relative;
  width: 100px;
  height: 1px;
  background-color: #000;
}
.arrow::after {
  content: '';
  position: relative;
  top: -5px;
  left: calc(50% - 5px);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #000 transparent;
}

.custom-un-button {
  background-color: #fff;
  border-color: #007bff;
  width: 100%;
  color: #007bff;
}

.custom-un-button:disabled {
  background-color: #fff;
  border-color: #b9d0e9;
  width: 100%;
  color: #a9c9eb;
}

.custom-button {
  background-color: #1677FF;
  border-color: #1677FF;
  width: 100%;
  color: white;
}

.custom-button:disabled {
  background-color: #85bffd;
  border-color: #85bffd;
  color: white;
  cursor: not-allowed;
  opacity: 0.65; /* Adjust opacity for disabled look */
}

.custom-tooltip .ant-tooltip-inner {
  background-color: white !important;
  color: black !important;
  border: 1px solid #d9d9d9 !important;
  width: 700px !important; /* Set the desired width */
  max-width: 700px !important; /* Make sure the max-width is the same */
  box-sizing: border-box; /* Include padding and border in the element's width and height */
  border-radius: 4px; /* Optional: rounded corners */
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

