.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 64px;
  padding: 8px;
  display: flex;
  align-items: center;
  background: white;
}

.site-layout .site-layout-background {
  background: #fff;
}

.sider-width-adjuster {
  transition: all ease 0.2s;
}

.login-card {
  max-width: 450px;
  margin: auto;
}

.ant-collapse-header {
  align-items: center !important;
}
.row {
  position: relative;
}
.span2 {
  position: absolute;
  left: 280px;
}
/* .span3 {
  position: absolute;
  left: 310px;
  font-weight: 600;
} */

/* .span3 {
  position: absolute;
  left: 295px;
  width: 250px;
  font-weight: 600;
  overflow: hidden;
  white-space: wrap;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
  z-index: 1; /* Ensures it stays in proper stacking order */
/*} */

.span3 {
  position: absolute;
  left: 295px;
  width: 250px;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap; /* Keep text on one line */
  text-overflow: ellipsis; /* Show dots for overflowing text */
  display: inline-block; /* Ensures proper inline behavior */
  vertical-align: middle;
  /* Ensures it stays in proper stacking order */
}

/* Ensure the tooltip is rendered correctly without affecting layout */
.ant-tooltip {
  position: absolute;
  z-index: 1050;
  pointer-events: auto; /* Ensures interactivity */
}

/* Adjust tooltip placement and prevent downward movement */
.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  margin-left: 0;
}

.address {
  position: relative !important;
  left: 150px !important;
  font-weight: 600 !important;
  width: 250px !important;
  text-transform: uppercase;
}
.paddress {
  position: relative !important;
  left: 170px !important;
  font-weight: 600 !important;
  width: 250px !important;
  text-transform: uppercase;
}
.Contractworkmenaddress {
  position: relative !important;
  left: 260px !important;
  font-weight: 600 !important;
  width: 250px !important;
  text-transform: uppercase;
}
placeholder {
  font-weight: bold;
}
rc-anchor-alert,
.rc-anchor-alert {
  visibility: hidden;
}
.Onboard {
  font-weight: bold;
  margin-left: 170px;
  margin-top: 0px;
  position: 'absolute';
}
.text {
  /* color:#ade8f4; */

  color: rgb(50, 205, 153);
  /* font-size:x-large; */
  font-size: x-large;
  /* font-weight: 4500; */
  font-family: 'Franklin Gothic Medium', 'Arial Narrow';
  /* font-family: 'Rubik', sans-serif; */

  /* font-family: 'Dancing Script', cursive; */
  font-weight: 600;
  margin-left: 95px;
  margin-bottom: 140px;
  /* margin-top: 30px; */
  /* padding-top: 130px; */
}
.text1 {
  color: darkred;
  font-style: inherit;
  font-size: larger;
  margin-left: 110px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.rc-anchor-alert {
  color: white;
}

#rc-anchor-alert,
.rc-anchor-alert {
  visibility: hidden;
  color: white;
}
.g-recaptcha {
  color: white;
}
.recaptha {
  color: white;
}
.grecaptcha-badge {
  display: none;
}

.ant-btn css-dev-only-do-not-override-16aepep ant-btn-primary {
  color: gold;
  background-color: aquamarine;
}

#rc-anchor-alert,
.rc-anchor-alert {
  visibility: hidden;
}

.exempt {
  padding-top: 40px;
  padding-left: 80px;
  font-weight: 500;
}
/* .ant-upload-list-item-name {
  visibility: hidden;
} */
.ant-upload-list-item {
  width: 200px;
}
.span3 {
  text-transform: uppercase;
}

.ant-card-head-title {
  text-align: center;
  font-weight: bold;
}

.ant-modal-content {
  background: fixed;
}
.qot {
  color: rgb(194, 115, 11);
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.App {
  font-family: 'Montserrat', sans-serif;
  text-align: start;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Button {
  color: white;
  background-color: blue;
  padding: 10px;
  width: 100px;
  font-weight: 800;
  letter-spacing: 2;
  cursor: pointer;
  border-radius: 10px;
  transform: all 1s cubic-bezier(0.42, 0, 0.17, 0.71);
  margin: 10px;
}

.Button:hover {
  background-color: red;
}

.dialog {
  padding: 20px;
  background: white;
  position: absolute;
  z-index: 500;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(37, 37, 37, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-content {
  background-color: white;
  margin: 0;
  width: 400px;
  height: 400px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  border-radius: 10px;
  align-items: flex-start;
  justify-content: space-between;
  overflow-y: auto;
}

.close {
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
}

.close > button {
  height: 36px;
  width: 36px;
  border: none;
  background: transparent;
  cursor: pointer;
}

.title {
  font-weight: 700;
  font-size: 1.5rem;
  margin: 0;
  padding-bottom: 5px;
}

@keyframes anime {
  0% {
    font-size: 10px;
  }
  30% {
    font-size: 15px;
  }
  100% {
    font-size: 12px;
  }
}

.detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0;
  height: 40px;
  border-bottom: 1px dashed;
  animation: slidein 2s;
}
.detail-first-child {
  border-top: 1px dashed;
  margin-top: 20px;
}

.detail-header {
  font-weight: bold;
  font-size: 1rem;
}

.detail-content {
  font-size: 1rem;
  padding-left: 5px;
}

.green {
  color: rgb(153, 255, 0);
}
.description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: fit-content;
}
.red {
  color: red;
}

.message {
  font-size: 0.8rem;
}
.small {
  font-size: 0.9rem;
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

.errorMessage {
  display: flex;
  align-content: center;
  align-items: center;
}

/* Spin */
.spinnerStyle {
  flex: 1;
  margin-top: '240px';
  justify-content: 'center';
  align-items: 'center';
}
.card {
  box-shadow: 4px 4px 8px 8px rgba(0, 0, 0, 0.2);
  max-width: 300px;
  margin: auto;
  text-align: center;
  font-family: arial;
}
.button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: red;
  background-color: rgb(24, 191, 237);
  text-align: center;
  cursor: pointer;
  font-size: 15px;
}
.title {
  color: grey;
  font-size: 18px;
}
.column {
  float: left;
  width: 50%;
  padding: 10px;
  height: 300px; /* Should be removed. Only for demonstration */
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

header {
  font-weight: bold;
}

.custom-modal .ant-modal-close-x {
  display: none;
}

.row-highlight .span3 {
  /* background-color: #ffeb3b; */
  color: red;
}
.row-highlight .address {
  color: red;
}
.row-highlight .paddress {
  color: red;
}

.highlight-cell {
  background-color: rgb(0, 242, 255);
  color: white;
  padding: 2px 4px;
}

.highlight-green {
  background-color: green;
  color: white;
  padding: 2px 4px; /* Adjust padding as needed */
}

/* Naveen Code */
.active {
  color: #32cd32;

  font-weight: 500 !important;
}

.success {
  color: #32cd32;
}

.pending {
  color:      /*rgb(255, 191, 0);*/ #d26200;
  /* padding: 2px 4px;
  border-radius: 4px; */
  font-weight: 500 !important;
}

.reject {
  color: red;
  font-weight: 500 !important;
}

.ant-table-cell {
  white-space: normal;
  word-wrap: break-word;
}
