.change-password {
  display: flex;
  justify-content: center;
  align-items: center;
}

.change-password form {
  width: 500px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.change-password h3 {
  text-align: center;
}
